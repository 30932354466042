<template>
  <el-row :gutter="10">
    <el-col v-if="(jobDataInner||{}).timesheet && isShowVesselAnchored " :sm="12" :md="8" :lg="4">
      <el-form-item  label="Vessel's Anchored/Berthed">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"

          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).vesselAnchored"
        />

      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Alongside">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"

          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeAlongside"
        />

      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Hose Connected">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).hosesConnected"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Commenced Pumping">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).commencedAt"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Completed Pumping">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).completedAt"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Hose Disconnected">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).loadingArmsDisconnected"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="12" :md="8" :lg="4">
      <el-form-item  label="Barge Sailed">
        <el-date-picker
          :format="DATETIME_FORMAT.OTH_DATETIME"
          type="date"
          style="width: 100%"
          :placeholder="DATETIME_FORMAT.DATETIME_PLACEHOLDER"
          :default-time="DATETIME_FORMAT.DEFAULT_TIME"
          :value-format="DATETIME_FORMAT.VALUE_FORMAT"
          :readonly="true"
          v-model="(jobDataInner.timesheet || {}).bargeSailed"
        />
      </el-form-item>
    </el-col>
  </el-row>
</template>
<script>
import { DATETIME_FORMAT } from '@/constants.js'
import { mapState } from "vuex";
export default {
  name: 'TimeSheetInformation',
  props: {
    jobDataInner: {
      type: Object,
      default: {}
    },
    jobData: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      DATETIME_FORMAT,

    }
  },
  computed: {
    ...mapState({
      physicalSupplierList: "physicalSupplierList",
    }),
    isShowVesselAnchored(){
     if (this.jobData?.type == 2 || !this.jobData?.bargeOrgId || this.physicalSupplierList.lenght<1) return false
     return  this.physicalSupplierList.find(x => x.id === this.jobData?.bargeOrgId)?.isExxonMobil
    }
  }


}
</script>

<style lang="scss" scoped>
/deep/.el-input__inner{
  padding-left: 30px;
}
</style>
